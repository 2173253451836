import { Provider } from '@vgtv/api-client';
import type { CnpTagId } from '@vgtv/api-client/lib/cnp_tag';
import type { SvpCategoryId } from '@vgtv/api-client/lib/svp_category';

export const NOT_FOUND_PAGE_SLUG = '404';

export const STORIES_TAG_ID =
  '57eff209-28df-4a1a-bf47-17c2fe4244c5' as CnpTagId;

// TODO: find a better way to filter out machine tags
export const RESTRICTED_TAGS = {
  [Provider.VGTV]: [
    'a9934b2f-9328-4bbc-a620-0d1e81fe0b66', // NENT
    '1edb9ec7-7902-4ff1-a8f8-7f61d8e5bac9', // VG: Mangfold
    '4af46dc8-721e-4e79-884b-1818af5d8a09', // vg:Ung-journalistikk
    STORIES_TAG_ID,
  ] as CnpTagId[],
  [Provider.AB]: [] as CnpTagId[],
};

// TODO: replace this list with the check for `archived` flag
export const RESTRICTED_CATEGORIES_VGTV = [
  100500, // partnerstudio
  100501, // partnerstudio
  33,
  43,
  100298,
  100234,
  132,
  100231,
  100232,
  100201,
  100057,
  100058,
  100062,
  100103,
  100244,
  100044,
  100051,
  100052,
  100107,
  100053,
  100061,
  100174,
  100006,
  4,
  6,
  8,
  111,
  113,
  224,
  242,
  236,
  100003,
  100105,
  100125,
  100145,
  100155,
  100207,
  100202,
  100236,
  100237,
  218,
  234,
  245,
  249,
  65,
  176,
  178,
  184,
  191,
  200,
  223,
  278,
  100046,
  100115,
  100117,
  100140,
  100164,
  100165,
  100166,
  100167,
  100168,
  100169,
  100173,
  100175,
  100176,
  100177,
  100178,
  100179,
  100180,
  100181,
  100182,
  100184,
  100185,
  100186,
  100187,
  100188,
  100191,
  100194,
  100198,
  100199,
  100205,
  100206,
  100208,
  100210,
  100211,
  100212,
  100213,
  100214,
  100215,
  100216,
  100217,
  100218,
  100220,
  100221,
  100223,
  100224,
  100225,
  100226,
  100233,
  100235,
  100240,
  100242,
  100245,
  100247,
  100248,
  100250,
  100252,
  100256,
  100258,
  100259,
  100261,
  100262,
  100263,
  100265,
  100270,
  100272,
  100273,
  100275,
  100276,
  100278,
  100280,
  100281,
  100284,
  100286,
  100287,
  100288,
  100290,
  100292,
  100300,
  100302,
  100303,
  100304,
  68,
  69,
  70,
  100333,
  100306,
  100308,
  100309,
  100310,
  100313,
  100315,
  100317,
  100322,
  100323,
  100325,
  100330,
  100335,
  63,
  133,
  100312,
  100326,
  100342,
  100340,
  100341,
  100344,
  100348,
  100350,
  100351,
  100352,
  100409,
  100353,
  100354,
  100359,
  100360,
  100363,
  100364,
  100365,
  100366,
  100367,
  100369,
  100372,
  100358, // slett
  100192, // Plaza-mysteriet
] as SvpCategoryId[];

export const RESTRICTED_CATEGORIES_AB = [1293] as SvpCategoryId[];
export const CATEGORIES_WITH_DISPLAY_ADS = [
  100147, 100065, 139, 100230, 100296, 237, 100230, 80, 100289, 100249, 100371,
] as SvpCategoryId[];

// TODO: move these to @vgtv/api-client
export const PAGE_TYPE_ASSET = 'asset';
export const COMPONENT_TYPE_LIST_IN_POPUP = 'list-in-popup';
export const COMPONENT_TYPE_GALLERY = 'gallery';
