import type { SvpAssetAdditional } from '@vgtv/api-client/lib/svp_asset';
import type { Logger } from '@datadog/browser-logs';
import type { Metric } from 'web-vitals/src/types/base';
import type { Page, TemplatePage } from '@vgtv/api-client/lib/page';

import { config } from '../config';

import { getDatadog } from './datadog';

let loggerPromise: Promise<Logger>;

const getLogger = async () => {
  if (loggerPromise) {
    return loggerPromise;
  }

  loggerPromise = getDatadog().then((dd) => dd.createLogger('performance'));

  return loggerPromise;
};

export const loadPlayer = async ({
  asset,
  initializePlayer,
}: {
  asset?: SvpAssetAdditional;
  initializePlayer: () => Promise<unknown>;
}) => {
  const startTime = window.performance.now();

  await initializePlayer();

  const joinTime = window.performance.now() - startTime;

  // don't await logger
  getLogger()
    .then((logger) => {
      logger.info('performance', {
        player: {
          join_time: joinTime,
        },
        content: {
          metadata: {
            content_id: asset?.id ?? -1,
            provider: asset?.provider ?? config.provider,
          },
        },
      });
    })
    .catch(() => {});
};

export const reportWebVitals = async (
  asset?: SvpAssetAdditional,
  page?: Exclude<Page, TemplatePage>
) => {
  const getPageType = () => {
    if (asset) {
      return 'asset';
    }

    return page?.type ?? 'unknown';
  };

  const onWebVitalReport = async ({ name, value }: Metric) => {
    const logger = await getLogger();

    logger.info('performance', {
      web_vital: {
        [name.toLowerCase()]: value,
      },
      page: {
        type: getPageType(),
      },
      content: {
        metadata: {
          content_id: asset?.id ?? -1,
          provider: asset?.provider ?? config.provider,
        },
      },
    });
  };

  try {
    const { onCLS, onINP, onLCP } = await import('web-vitals');

    onCLS(onWebVitalReport);
    onINP(onWebVitalReport);
    onLCP(onWebVitalReport);
  } catch {
    // noop
  }
};
