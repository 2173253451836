import { UnleashClient } from '../shared/unleashClient';
import type { Unleash, FeatureToggle } from '../shared/unleashClient';
import { setUnleash } from '../shared/globals';

import { consentManager } from './privacy.js';

const resolveToggles = (toggles: FeatureToggle[]) => {
  if (consentManager.getPermissionSync('CMP:analytics') === '1') {
    return toggles;
  }

  return toggles.map((toggle) => {
    // disable releases and experiments for users who didn't grant analytical and product development consents
    if (toggle.type === 'release' || toggle.type === 'experiment') {
      return {
        ...toggle,
        enabled: false,
        variant: {
          name: 'disabled',
          enabled: false,
        },
      };
    }

    return toggle;
  });
};

const unleash = new UnleashClient(
  resolveToggles(Array.isArray(window.FEATURES) ? window.FEATURES : [])
) as Unleash;

setUnleash(unleash);

export { unleash };
