import type { Opaque } from '@vgtv/api-client/lib/utils';

export enum PageTypeAdditional {
  ASSET = 'asset',
}

export const VGTVCategory = {
  NEWS: 1 as Opaque<1, 'SvpCategory'>,
  SPORT: 3 as Opaque<3, 'SvpCategory'>,
  FOOTBALL: 52 as Opaque<52, 'SvpCategory'>,
  ENTERTAINMENT: 100324 as Opaque<100324, 'SvpCategory'>,
  SPOTLIGHT: 5 as Opaque<5, 'SvpCategory'>,
  VIRAL: 279 as Opaque<279, 'SvpCategory'>,
  DOCUMENTARY: 121 as Opaque<121, 'SvpCategory'>,
} as const;

export const ABCategory = {
  NEWS: 4 as Opaque<4, 'SvpCategory'>,
  SPORT: 5 as Opaque<5, 'SvpCategory'>,
} as const;

export type Vendor = 'vgtv' | 'ab' | 'brandstudio';

declare module '@vgtv/api-client/lib/svp_asset' {
  export interface SvpAsset {
    provider: Vendor;
  }
}
