import type { SvpAssetAdditional } from '@vgtv/api-client/lib/svp_asset';

import type { Vendor } from '../../types.js';
import { deeplinksSupported } from '../../utils/device.js';
import { config } from '../../config.js';
import { getAssetShortPath } from '../../player/playerAssetHelpers.js';

import { getCategoryShortPath } from './categoryHelpers.js';

export const BRANDSTUDIO_ROUTE_PREFIX = '/annonsorinnhold';

export interface AssetPagePathParams {
  vendor: Vendor;
  assetId: number;
  titleShortPath?: string;
  start?: number;
  time?: number;
}

export interface AssetPagePathParamsV2 {
  asset: SvpAssetAdditional;
  start?: number;
  time?: number;
  skipDeeplinkCheck?: true;
  version?: 'v2' | 'v1';
}

export const getAssetPagePathV2 = ({
  asset,
  start,
  time,
  skipDeeplinkCheck,
  version = 'v2',
}: AssetPagePathParamsV2): string => {
  const titleShortPath = getAssetShortPath(asset);

  if (
    version === 'v2' &&
    asset.provider === 'vgtv' &&
    asset.category.id === 121 &&
    asset.additional.metadata.contentType === 'documentary'
  ) {
    if (!skipDeeplinkCheck && deeplinksSupported) {
      return `video:${asset.id}?provider=${config.provider}`;
    }

    let route = `/dokumentar/${titleShortPath}?id=${asset.id}`;

    if (time) {
      route += `&t=${time}s`;
    }

    return route;
  }

  if (version === 'v2' && asset.provider === 'vgtv' && asset.series) {
    if (!skipDeeplinkCheck && deeplinksSupported) {
      return `video:${asset.id}?provider=${config.provider}`;
    }

    const categoryShortPath = getCategoryShortPath(asset.category);

    let route = `/serier/${categoryShortPath}/s${asset.series.seasonNumber}e${asset.series.episodeNumber}/${titleShortPath}?id=${asset.id}`;

    if (time) {
      route += `&t=${time}s`;
    }

    return route;
  }

  return getAssetPagePath(
    {
      vendor: asset.provider,
      assetId: asset.id,
      titleShortPath,
      start,
      time,
    },
    skipDeeplinkCheck
  );
};

export function getAssetPagePath(
  { vendor, assetId, titleShortPath, start, time }: AssetPagePathParams,
  skipDeeplinkCheck = false
): string {
  if (!skipDeeplinkCheck && deeplinksSupported && vendor !== 'brandstudio') {
    return `video:${assetId}?provider=${config.provider}`;
  }

  let route = `/video/${assetId}`;

  if (titleShortPath) {
    route += `/${titleShortPath}`;
  }

  if (start) {
    route += `/${start}`;
  }

  if (time) {
    route += `?t=${time}s`;
  }

  if (vendor === 'brandstudio') {
    route = BRANDSTUDIO_ROUTE_PREFIX + route;
  }

  return route;
}
