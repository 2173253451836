import { isMobile, isTablet } from '../../utils/device.js';

/**
 * Returns a normalized device type aligned with the ones used in AppNexus (e.g. phone instead of mobile).
 */
function getDeviceType() {
  if (isMobile) {
    return 'phone';
  }

  if (isTablet) {
    return 'tablet';
  }

  return 'desktop';
}

export { getDeviceType };
