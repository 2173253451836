import { ConsentManager } from '@vgtv/consent-manager';
import type { Category } from '@vgtv/consent-manager';

import { UnleashClient } from '../shared/unleashClient';
import type { Unleash } from '../shared/unleashClient';

// "local" unleash instance to fix circular dependency issues (will be removed together with "CMP:*" toggles)
const unleash = new UnleashClient(
  Array.isArray(window.FEATURES) ? window.FEATURES : []
) as Unleash;

const getAllowlist = () => {
  const allowlist: Category[] = [];

  if (unleash.isEnabled('cmp-advertising')) {
    allowlist.push('CMP:advertising');
  }

  if (unleash.isEnabled('cmp-personalisation')) {
    allowlist.push('CMP:personalisation');
  }

  if (unleash.isEnabled('cmp-analytics')) {
    allowlist.push('CMP:analytics');
  }

  if (unleash.isEnabled('cmp-marketing')) {
    allowlist.push('CMP:marketing');
  }

  return allowlist;
};

export const consentManager = new ConsentManager({ allowlist: getAllowlist() });
