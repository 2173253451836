import type { LogsGlobal } from '@datadog/browser-logs';

import { config } from '../config';
import { isHermesApp } from '../utils/device';

import { getDeviceType } from './appnexus/getDeviceType';

const {
  datadog: {
    logs: {
      clientToken,
      service = 'frontend',
      env = 'development',
      version,
      level,
    },
  },
  provider,
} = config;

let datadogPromise: Promise<LogsGlobal>;

const initDatadog = () => {
  return new Promise<LogsGlobal>((resolve) => {
    window.DD_LOGS?.onReady(() => {
      window.DD_LOGS?.init({
        clientToken,
        service,
        env,
        version,
        site: 'datadoghq.eu',
      });

      window.DD_LOGS?.setGlobalContext({
        custom: {
          provider,
          deviceType: getDeviceType(),
          isApp: isHermesApp,
        },
      });
      window.DD_LOGS?.logger.setLevel(level);

      resolve(window.DD_LOGS!);
    });
  });
};

export const getDatadog = () => {
  if (datadogPromise) {
    return datadogPromise;
  }

  datadogPromise = initDatadog();

  return datadogPromise;
};
