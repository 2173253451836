export const isLocalStorageAvailable = (() => {
  if (typeof window !== 'undefined' && window.localStorage) {
    let isStorageSupported = true;

    try {
      localStorage.setItem('foo', 'foo');
      localStorage.removeItem('foo');
    } catch (err) {
      isStorageSupported = false;
    }

    return isStorageSupported;
  }

  return false;
})();

/**
 * Checks the availability of sessionStorage and does an extra check for safari private mode
 */
export const isSessionStorageAvailable = (() => {
  if (typeof window !== 'undefined' && window.sessionStorage) {
    let isStorageSupported = true;

    try {
      sessionStorage.setItem('foo', 'foo');
      sessionStorage.removeItem('foo');
    } catch (err) {
      isStorageSupported = false;
    }

    return isStorageSupported;
  }

  return false;
})();
