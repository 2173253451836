/**
 * Returns an object with query string parameters (key-value pairs) extracted from the current url.
 */
function getQueryStringParams<T extends string>(search?: string) {
  const params = new URLSearchParams(search ?? window.location.search);

  return [...params].reduce<Record<string, string>>((params, [key, value]) => {
    params[key] = value;
    return params;
  }, {}) as Partial<Record<T, string>>;
}

export { getQueryStringParams };
