import type { Variant } from 'unleash-client';

const defaultVariant: Variant = { name: 'disabled', enabled: false };

export interface FeatureToggle {
  name: string;
  type: 'release' | 'experiment' | 'kill-switch' | 'permission' | 'operational';
  enabled: boolean;
  variant: Variant;
}

type KeyOf<T> = Extract<keyof T, string>;

export class UnleashClient<T extends Record<string, Omit<Variant, 'enabled'>>> {
  constructor(private readonly toggles: FeatureToggle[]) {}

  private resolveToggle(name: string) {
    return this.toggles.find((toggle) => toggle.name === name);
  }

  isEnabled(name: KeyOf<T>, fallbackValue = false) {
    const feature = this.resolveToggle(name);

    return feature?.enabled ?? fallbackValue;
  }

  getVariant<K extends KeyOf<T>>(name: K, fallbackVariant = defaultVariant) {
    const feature = this.resolveToggle(name);

    return (feature?.variant as T[K] & { enabled: boolean }) ?? fallbackVariant;
  }

  getAllToggles() {
    return this.toggles;
  }
}

export type Unleash = UnleashClient<{
  watchtime: never;
  brandstudio: never;
  'cmp-advertising': never;
  'cmp-analytics': never;
  'cmp-marketing': never;
  'cmp-personalisation': never;
}>;
