import { getDocumentStyle } from './getDocumentStyle';

/**
 * Returns a value of a custom style property (e.g. CSS variable).
 */
function getStyleProperty(key: string): string {
  const documentStyle = getDocumentStyle();

  if (key.indexOf('--') !== 0) {
    console.warn(`Style property name '${key}' appears to be invalid.`);
  }

  return documentStyle.getPropertyValue(key);
}

export { getStyleProperty };
