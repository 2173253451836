import { initializePlayer as setupPlayer } from './player/playerService.js';
import { storeUtmParams } from './services/tracking/helpers/utmParams.js';
import { resolveIn } from './utils/async.js';
import { getDatadog } from './services/datadog.js';
import { loadPlayer, reportWebVitals } from './services/performance.js';

// make sure that no unnecessary dependencies are not imported in this file
// - this way the initial bundle size is minimal, and we can prioritize player loading

import './styles/player.scss';

storeUtmParams();

// init datadog
getDatadog();

async function initializeApp() {
  await (
    await import('./i18n/i18n')
  ).default;
  import('./appRenderer');
}

const initializePlayer = () => {
  return Promise.race([
    loadPlayer({
      asset: window.ASSET,
      initializePlayer: () => setupPlayer(window.ASSET),
    }),
    resolveIn(2.5 * 1000),
  ]);
};

if (window.ASSET) {
  // when entering an asset page - prioritize player setup over app initialization
  initializePlayer()
    .then(() => initializeApp())
    .catch(console.error);
} else {
  initializePlayer();
  initializeApp();
}

// measure CWV only for 50% of traffic
if (Math.random() < 0.5) {
  reportWebVitals(window.ASSET, window.PAGE);
}
