export enum OrientationType {
  LANDSCAPE = 'landscape',
  PORTRAIT = 'portrait',
}

export const isScreenOrientationSupported =
  typeof ScreenOrientation !== 'undefined';

export function getScreenOrientation(): OrientationType {
  if (isScreenOrientationSupported) {
    return window.screen?.orientation?.type?.includes?.('portrait')
      ? OrientationType.PORTRAIT
      : OrientationType.LANDSCAPE;
  }

  // `orientation` property is missing in desktop browsers
  if (typeof window.orientation === 'undefined') {
    return OrientationType.LANDSCAPE;
  }

  return Math.abs(window.orientation) !== 90
    ? OrientationType.PORTRAIT
    : OrientationType.LANDSCAPE;
}
