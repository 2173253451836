import { Provider } from '@vgtv/api-client';
import type { UnixTimestamp } from '@vgtv/api-client';
import type {
  SvpAsset,
  SvpAssetAdditional,
} from '@vgtv/api-client/lib/svp_asset';
import type { ProviderAccessMap } from '@vgtv/svp-access/lib/access_mappings';
import type { AssetMetadata } from '@schibsted-svp/svp-api-types';

import { config } from '../config.js';
import { ABCategory, VGTVCategory } from '../types.js';
import { CATEGORIES_WITH_DISPLAY_ADS } from '../consts.js';
import { formatRouteParam } from '../utils/string.js';

/**
 * Returns type of token security for an asset, which can be mapped to subscription type.
 * `premium` or `login` access level is accessible for logged in users, while `plus` and `sports` are
 * accessible for users with specific VG+ subscriptions.
 */
export function getAssetAccessLevel<T extends Provider>(asset: {
  additional?: {
    access?: SvpAssetAdditional['additional']['access'];
  };
}): ProviderAccessMap[T] | undefined {
  const access = asset?.additional?.access ?? {};

  return Object.keys(access).length > 0
    ? (Object.keys(access)[0] as ProviderAccessMap[T])
    : undefined;
}

/**
 * Returns true if asset has paywall access setup to any subscription type.
 */
export function isAssetPaywalled(asset: {
  additional?: {
    access?: SvpAssetAdditional['additional']['access'];
  };
}): boolean {
  const access = getAssetAccessLevel(asset);
  return access !== undefined;
}

/**
 * Returns the start flight time for assets planned to be accessible in the future.
 */
export function getAssetFlightTimesStart(
  asset: SvpAsset
): UnixTimestamp | undefined | null {
  const { flightTimes } = asset;

  return flightTimes?.start!;
}

/**
 * Returns true for assets which are planned to be accessible in the future, e.g.
 * their flightTimes.start is set to a future time.
 */
export function isAssetUpcoming(
  asset: SvpAsset,
  relativeTo?: UnixTimestamp
): boolean {
  const start = getAssetFlightTimesStart(asset);

  if (!start) {
    return false;
  }

  const nowUnixTimestamp = relativeTo || Date.now() / 1000;

  return nowUnixTimestamp < start;
}

export function isSportAsset(asset: SvpAsset): boolean {
  const { category } = asset;

  if (config.provider === Provider.VGTV) {
    return (
      category.id === VGTVCategory.SPORT ||
      category.parentId === VGTVCategory.SPORT
    );
  } else {
    return (
      category.id === ABCategory.SPORT || category.parentId === ABCategory.SPORT
    );
  }
}

export function isDocumentary(asset: SvpAsset): boolean {
  const { category } = asset;

  if (config.provider === Provider.VGTV) {
    return (
      category.id === VGTVCategory.DOCUMENTARY ||
      category.parentId === VGTVCategory.DOCUMENTARY
    );
  }

  return false;
}

/**
 * Checks whether a companion ad will most likely be shown for this asset
 * based on its category and a preset list of `CATEGORIES_WITH_DISPLAY_ADS`.
 */
export function willAssetHaveDisplayAd(asset: SvpAsset): boolean {
  return asset.category
    ? CATEGORIES_WITH_DISPLAY_ADS.includes(asset.category.id)
    : false;
}

export const DEFAULT_ASSET_ASPECT_RATIO = 16 / 9;

export const normalizeAspectRatio = (aspectRatio?: number) => {
  const normalizedValue = parseFloat(
    (aspectRatio || DEFAULT_ASSET_ASPECT_RATIO).toFixed(2)
  );

  if (normalizedValue === 0.56) {
    // force 2/3 aspect ratio for vertical videos to save space
    return parseFloat((2 / 3).toFixed(2));
  }

  return normalizedValue;
};

const emptyAssetMetadata: SvpAssetAdditional['additional']['metadata'] = {};
Object.freeze(emptyAssetMetadata);
export const getAssetMetadata = (asset?: {
  additional?: { metadata?: AssetMetadata };
}) => asset?.additional?.metadata || emptyAssetMetadata;

export const getAssetShortPath = (
  asset: {
    additional: Pick<SvpAssetAdditional['additional'], 'metadata'>;
  } & Pick<SvpAssetAdditional, 'title'>
) => getAssetMetadata(asset).titleShortPath ?? formatRouteParam(asset.title);
