let computedStyle: CSSStyleDeclaration;

const getDocumentStyle = (): CSSStyleDeclaration => {
  if (!computedStyle) {
    computedStyle = getComputedStyle(document.documentElement);
  }

  return computedStyle;
};

export { getDocumentStyle };
