function hasAdblock(): boolean {
  const el = document.createElement('div');

  el.id = 'block_advert';
  el.innerHTML = '&nbsp;';
  el.style.width = '1px';
  el.style.height = '1px';
  el.style.position = 'absolute';
  el.style.background = 'transparent';

  document.body.appendChild(el);

  const hasAdblock =
    !document.body.contains(el) ||
    el.offsetParent === null ||
    el.id !== 'block_advert' ||
    el.innerHTML === '' ||
    el.clientHeight === 0;

  document.body.removeChild(el);

  return hasAdblock;
}

export { hasAdblock };
