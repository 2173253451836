/**
 * @param timeout - in how many ms the promise should reject
 */
function rejectIn(timeout: number): Promise<void> {
  return new Promise((resolve, reject) => setTimeout(reject, timeout));
}

/**
 * @param timeout - in how many ms the promise should resolve
 */
function resolveIn(timeout: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, timeout));
}

export const resolveWithDelay = <T>(timeout: number, value: T): Promise<T> => {
  return new Promise<T>((resolve) => setTimeout(() => resolve(value), timeout));
};

export const resolveWithTimeout = async <T>({
  promise,
  fallback,
  timeout,
}: {
  promise: Promise<T>;
  fallback: T;
  timeout: number;
}) => {
  return Promise.race([promise, resolveWithDelay(timeout, fallback)]);
};

const ensureDelay = async <T>(
  promise: Promise<T> | (() => Promise<T>),
  timeout: number
): Promise<T> => {
  const [result] = await Promise.all([
    typeof promise === 'function' ? promise() : promise,
    resolveIn(timeout),
  ]);

  return result;
};

export { rejectIn, resolveIn, ensureDelay };
