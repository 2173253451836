/**
 * Turns an object containing style declarations, into a valid CSS Style Declaration string.
 */
function stringifyStyles(
  stylesObject: Record<string, string | number>
): string {
  return Object.keys(stylesObject).reduce((style, key) => {
    style += `${key}: ${stylesObject[key]};`;

    return style;
  }, '');
}

export { stringifyStyles };
