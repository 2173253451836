import { stringifyStyles } from '../utils/strigifyStyles';
import { getStyleProperty } from '../utils/getStyleProperty';
import {
  getScreenOrientation,
  OrientationType,
} from '../utils/screenOrientation';

import { getPlayer } from './playerService';
import { DEFAULT_ASSET_ASPECT_RATIO } from './playerAssetHelpers';

const PLAYER_CONTAINER_ID = 'player-container';

let playerContainerNode: HTMLElement | null = null;
let positionContainerNode: HTMLElement | null = null;
let shownOnRender = false;
let affixEnabled = false;
let aspectRatio = DEFAULT_ASSET_ASPECT_RATIO;

/**
 * @return {HTMLElement} - player container element
 */
function getContainer(): HTMLElement | null {
  if (!playerContainerNode) {
    playerContainerNode = document.getElementById(PLAYER_CONTAINER_ID);
  }

  return playerContainerNode;
}

function setPositionNode(node: HTMLElement | null) {
  positionContainerNode = node;
  positionOverNode();
}

function setAspectRatio(ratio: number = aspectRatio) {
  aspectRatio = ratio;
  refreshAspectRatio();
}

function refreshAspectRatio() {
  const orientation = getScreenOrientation();
  if (orientation === OrientationType.PORTRAIT) {
    getPlayer()
      .then((player) => {
        positionContainerNode?.style.setProperty(
          'aspect-ratio',
          String(
            affixEnabled && player.getState() !== 'adPlaying'
              ? DEFAULT_ASSET_ASPECT_RATIO
              : aspectRatio
          )
        );

        positionOverNode();
      })
      .catch(console.error);
  } else {
    positionOverNode();
  }
}

/**
 * Visually moves the player container above the given node.
 */
function positionOverNode() {
  const container = getContainer();
  if (!container || !positionContainerNode) {
    return;
  }

  const rect = positionContainerNode.getBoundingClientRect();
  const { scrollX, scrollY } = window;
  const style = {
    top: `${scrollY + rect.y}px`,
    left: `${scrollX + rect.x}px`,
    width: `${rect.width}px`,
    height: `${rect.height}px`,
  };

  container.style.cssText = stringifyStyles(style);
}

export interface Dimensions {
  width: number;
  height: number;
}

function getDimensions(): Dimensions {
  const container = getContainer();

  if (container) {
    return {
      width: container.clientWidth,
      height: container.clientHeight,
    };
  }

  return { width: 0, height: 0 };
}

function show() {
  const container = getContainer();
  if (!container) {
    return;
  }

  positionOverNode();

  if (shownOnRender) {
    container.classList.remove('shownOnRender');
    shownOnRender = false;
  }

  container.classList.add('visible');
  container.classList.remove('hidden');
}

function hide() {
  const container = getContainer();
  if (container) {
    container.classList.remove('visible');
    container.classList.add('hidden');
  }
}

function showOnRender({ offsetForDisplayAd = false }) {
  show();

  // by deriving and setting the actual player position we can avoid styling changes
  // (transform -> absolute positioning) being marked as a layout shift, and added to the CLS score
  const maxPlayerWidth = parseInt(getStyleProperty('--max-player-width'));

  const expectedPlayerWidth = Math.min(
    window.innerWidth,
    !isNaN(maxPlayerWidth) ? maxPlayerWidth : Infinity
  );

  const container = getContainer();

  if (container) {
    container.style.left = `${(window.innerWidth - expectedPlayerWidth) / 2}px`;

    container.classList.add('shownOnRender');

    if (offsetForDisplayAd) {
      container.classList.add('offsetForDisplayAd');
    }
  }

  shownOnRender = true;
}

function affix(fixedPlayerPosition = false) {
  if (fixedPlayerPosition === affixEnabled) {
    return;
  }

  affixEnabled = fixedPlayerPosition;

  const container = getContainer();
  if (container) {
    if (fixedPlayerPosition) {
      container.classList.add('affixed');
      refreshAspectRatio();
    } else {
      container.classList.remove('affixed');
      refreshAspectRatio();
    }
  }
}

const playerContainer = {
  positionOverNode,
  setAspectRatio,
  setPositionNode,
  getDimensions,
  show,
  hide,
  showOnRender,
  affix,
};

export { playerContainer };
