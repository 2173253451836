import { getQueryStringParams } from '../../../utils/getQueryStringParams';

export interface UtmParameters {
  utm_source?: string;
  utm_medium?: string;
  utm_channel?: string;
  utm_campaign?: string;
  utm_term?: string;
  utm_content?: string;
}

let utmParams: UtmParameters = {};

export const isUtmParamKey = (key: string): key is keyof UtmParameters => {
  return /^utm_(source|medium|channel|campaign|term|content)/.test(key);
};

/**
 * Extracts {@link https://en.wikipedia.org/wiki/UTM_parameters|utm parameters} from the current url query string.
 */
export function readUtmParams(): UtmParameters {
  const params = getQueryStringParams();

  return Object.keys(params).reduce<Partial<UtmParameters>>(
    (utmParams, key) => {
      if (isUtmParamKey(key)) {
        utmParams[key] = params[key];
      }

      return utmParams;
    },
    {}
  );
}

export function storeUtmParams() {
  utmParams = readUtmParams();
}

export const getUtmParams = () => utmParams;
