let storage: Record<string, string> = {};

export default {
  setItem(key: string, value: unknown) {
    storage[key] = String(value);
  },
  getItem(key: string): string | null {
    return storage[key] ?? null;
  },
  removeItem(key: string) {
    delete storage[key];
  },
  clear() {
    storage = {};
  },
};
