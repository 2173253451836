import type { Provider } from '@vgtv/api-client';
import type { TagDefinition } from 'xandr';
import type { StatusType } from '@datadog/browser-logs';

import type { IconName } from './components/Icon/names';

export interface CommonAppConfig {
  env: 'development' | 'feature' | 'staging' | 'production' | 'test';
  datadog: {
    tracer: {
      enabled: boolean;
      logInjection: boolean;
      runtimeMetrics: boolean;
    };
    logs: {
      enabled: boolean;
      clientToken: string;
      env?: string;
      service?: string;
      version?: string;
      level: StatusType;
    };
  };
  schAccApi: {
    rootUrl: string;
    audience: string;
  };
  watchtime: {
    /**
     * A percentage - minimum offset after watch time is saved
     */
    skipStartPercentage: number;
    /**
     * A percentage - offset from end after which time is not saved or deleted (if it's stored already)
     */
    skipEndPercentage: number;
    /**
     * In seconds - maximum skip window
     */
    maxSkipWindow: number;
    /**
     * In seconds - save time only if video has been watched continuously for more than specified value
     */
    minSeekDiff: number;
    /**
     * In seconds - save watch times only if at least X seconds passed from previous update
     */
    saveInterval: number;
  };
  unleash: {
    apiUrl: string;
  };
}

export interface MenuItemConfig {
  label: string;
  link: string;
  iconName?: IconName;
  id: number | string;
  type?: string;
}

interface BaseAppConfig extends CommonAppConfig {
  provider: Provider;
  iterate: {
    enabled: boolean;
    apiKey: string;
  };
  apiHostname: string;
  svpApiHostname: string;
  appName: string;
  supportPageUrl: string;
  mySubscriptionUrl: string;
  newsletterUrl: string;
  watchtimeApiHostname: string;
  spsApiHostname: string;
  collectionsApiHostname: string;
  enableServiceWorker: boolean;
  disablePlayerAds: boolean;
  enableTracking: boolean;
  featureToggles: {
    enableAdsDynamicAspectRatio: boolean;
  };
  unleash: {
    apiUrl: string;
  };
  psi: {
    // https://github.schibsted.io/ab/psi3-tag-builder
    // https://github.schibsted.io/vg/psi3-tag-builder
    scriptUrl: string;
  };
  enableFavourites: boolean;
  enableParentalControls: boolean;
  sitemapHostname: string;
  appleAppSiteAssociation: Record<string, unknown>;
  mobileMenuItemsCount: number;
  desktopMenuItemsCount: number;
  menuItems: MenuItemConfig[];
  player: {
    cdnUrl: string;
  };
  appnexus: {
    debug: boolean;
    options: Record<string, unknown>;
    placements: Partial<
      Record<
        'web' | 'app',
        Record<
          'desktop' | 'tablet' | 'phone',
          Array<
            Partial<TagDefinition> &
              Pick<TagDefinition, 'invCode' | 'sizes' | 'targetId'>
          >
        >
      >
    >;
    keywords: Record<string, string | string[]>;
  };
  pulse: {
    site: 'vgtv' | 'aftonbladet';
    actorRealm: string;
    productTag: string;
  };
  dataController: {
    link: string;
  };
  /**
   * A list of static path redirects
   */
  redirects: {
    from: string;
    to: string;
  }[];
  /**
   * Routes which are allowed to pass through server and are handled in the frontend only
   */
  staticRoutes: Record<string, string>;
  subscription: {
    price: number;
    inAppPrice: number;
    landingPageUrl: string;
    salesposterArticle?: string;
  };
  asd: {
    eventhub: {
      rootUrl: string;
      clientToken: string;
    };
    enabled: boolean;
    maxSessions: number;
  };
  appStoreLink: string;
  googlePlayLink: string;
}

interface VGTVAppConfig extends BaseAppConfig {
  provider: Provider.VGTV;
  editorialBindingsUrl: string;
  schibstedAccount: {
    clientId: string;
    env: 'PRE' | 'PRO_NO';
    sessionDomain: string;
    realmDomain: string;
    issuer: string;
    myPageUrl: string;
  };
}

interface ABAppConfig extends BaseAppConfig {
  provider: Provider.AB;
  schibstedAccount: {
    clientId: string;
    env: 'PRE' | 'PRO';
    sessionDomain: string;
    realmDomain: string;
    issuer: string;
    myPageUrl: string;
  };
}

export type AppConfig = VGTVAppConfig | ABAppConfig;

const config = {} as AppConfig;

if (typeof window !== 'undefined' && window.REACT_APP_CONFIG) {
  Object.assign(config, { ...(window.REACT_APP_CONFIG || {}) });
  delete window.REACT_APP_CONFIG;
}

export { config };
