import { initialize } from '@vgtv/device-utils';

const data = initialize();

export const {
  isIPad,
  isIOS,
  isAndroid,
  isMobile,
  isTablet,
  isHermesApp,
  isAftonbladetApp,
  isVgApp,
  isSportApp,
  deeplinksSupported,
  isHighDpiDevice,
  isTouchDevice,
} = data;

export const isHeaderless =
  (typeof window !== 'undefined' &&
    window.navigator?.userAgent.match(/VGTV-IN-TAB-BAR/) !== null) ||
  isSportApp;
